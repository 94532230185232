import StorageView from "../../views/storage";
import HomeView from "../../views/home";
import SearchView from "../../views/search";
import TrashView from "../../views/trash";
import SettingsView from "../../views/admin/settings";
import FavouritesView from "../../views/favourites";
import SharedStorageView from "../../views/shared-storage";
import { UserProfile } from "../../../proxima-admin/components/user-profile/user-profile";
import { Users } from "../../../proxima-admin/components/user-list/users";

let routes = [
  {
    headerText: "Proxima Storage",
    path: "/home",
    id: "HOME", //Used for creating navigate links
    name: "Home",
    icon: "material-icons-outlined",
    iconName: "home",
    component: HomeView,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    path: "/storage",
    name: "My Storage",
    id: "MY_STORAGE", //Used for creating navigate links
    icon: "material-icons-outlined",
    iconName: "folder",
    component: StorageView,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    path: "/sharedstorage",
    name: "Shared Storage",
    id: "SHARED_STORAGE", //Used for creating navigate links
    icon: "material-icons-outlined",
    iconName: "folder_shared",
    component: SharedStorageView,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    path: "/favourites",
    name: "Favourites",
    id: "FAVOURITES", //Used for creating navigate links
    icon: "material-icons-outlined",
    iconName: "grade",
    component: FavouritesView,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    path: "/trash",
    name: "Trash",
    id: "TRASH", //Used for creating navigate links
    icon: "material-icons-outlined",
    iconName: "delete",
    component: TrashView,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    headerText: "Admin Settings",
    path: "/settings",
    name: "Settings",
    icon: "material-icons-outlined",
    iconName: "settings",
    component: SettingsView,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    path: "/users/",
    name: "Manage Users",
    icon: "material-icons",
    iconName: "people",
    component: Users,
    layout: "/app",
    isHiddenInMobile: true,
    showInSidebar: true,
  },
  {
    path: "/search",
    name: "Search results",
    id: "SEARCH", //Used for creating navigate links
    component: SearchView,
    layout: "/app",
    showInSidebar: false,
  },
  {
    path: "/profile",
    name: "Profile",
    id: "PROFILE", //Used for creating navigate links
    component: UserProfile,
    layout: "/app",
    showInSidebar: false,
  },
 

];
export default routes;
